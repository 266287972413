<template>
  <div class="Login">
    <div class="goHome" @click="handleGo">前去首页</div>
    <div class="top">
      <img src="../assets/image/LOGO.png" alt />
    </div>
    <div class="form">
      <van-field v-model="formData.mobile" clearable placeholder="手机号" />
      <van-field v-model="formData.code" clearable placeholder="验证码" />
      <span @click="handelGetCode(30)" class="code">{{codeText}}</span>
    </div>
    <div class="btn bottom" @click="handlegetMsg">完善信息</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        mobile: "",
        code: ""
      },
      codeText: "获取验证码"
    };
  },
  methods: {
    async handelGetCode(time) {
      if (this.formData.mobile == "") {
        this.$notify({ type: "danger", message: "手机号码不能为空" });
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.formData.mobile)) {
        this.$notify({ type: "danger", message: "手机号码格式有误！" });
        return;
      }
      const data = await this.$api.getPhoneCode({
        mobile: this.formData.mobile
      });
      if (data.data.status == 1) {
        this.$notify({ type: "success", message: "发送成功！"});

        if (this.codeText !== "获取验证码") {
          return;
        }
        const timer = setInterval(() => {
          time -= 1;
          this.codeText = `${time}s重新获取`;
          if (time == 0) {
            clearInterval(timer);
            this.codeText = "获取验证码";
          }
        }, 1000);
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    //短信验证
    async handlegetMsg() {
      const data = await this.$api.getMsg({
        ...this.formData
      });
      if (data.data.code === 1) {
        this.$router.push({
          path: "/information"
        });
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
      console.log(data, "data");
    },
    handleGo(){
      this.$router.push({
        path:"/index"
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.Login {
  width: 100%;
  height: 100%;
  background: #fff;
  .goHome{
    width:375px;
    height:40px;
    padding:0 12px;
    font-size: 16px;
    line-height: 40px;
    color:#00B100;
    text-align: right;
    box-sizing: border-box;
  }
  .top {
    width: 117px;
    //height: 47px;
    margin: 0 auto;
    margin-top: 27px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .form {
    position: relative;
    margin-top: 56px;
    .code {
      min-width: 65px;
      height: 13px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(41, 153, 250, 1);
      line-height: 13px;
      position: absolute;
      top: 79px;
      right: 32px;
    }
  }
  .bottom {
    margin-top: 98px;
  }
}
</style>
